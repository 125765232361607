<template>
  <div class="caixas-saidas">
    <!-- {{ $props.dataInicial }} | {{ $props.EmpresaAtiva.cod_empresa }} | {{ $props.caixaTipo }} -->
    <div class="row mb-2">
      <div class="col" v-if="withData">
        <b-input type="date" v-model="data" style="max-width: 200px" />
      </div>
      <div class="col text-left">
        <h5>Controle (Abertura / Fechamento)</h5>
      </div>
      <div class="col text-right">
        <b-button
          variant="primary"
          @click="addFechamento"
          :disabled="!canEdit || loading || lista.length > 0"
        >
          <i class="fa fa-plus" /> Novo Controle
        </b-button>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-sm-6"
        v-for="cx in lista"
        :key="`cx_${cx.cod_fechamento}`"
      >
        <div class="card">
          <div class="card-body">
            <h5>{{ cx.caixa }} {{ cx.moto }}</h5>
            <hr />
            <table
              class="table table-small table-striped table-bordered"
              style="font-size: 10px"
            >
              <tr>
                <th>Valor Aberto</th>
                <td>{{ cx.vl_aberto | currency }}</td>
              </tr>
              <tr>
                <th>Dinheiro</th>
                <td>{{ cx.vl_dinheiro | currency }}</td>
              </tr>
              <tr>
                <th>Moedas</th>
                <td>{{ cx.vl_moedas | currency }}</td>
              </tr>
              <tr>
                <th>Cartão</th>
                <td>{{ cx.vl_cartao | currency }}</td>
              </tr>
              <tr>
                <th>Sysprocard</th>
                <td>{{ cx.vl_sysprocard | currency }}</td>
              </tr>
              <tr>
                <th>Vale Hidromar</th>
                <td>{{ cx.vl_hidromar | currency }}</td>
              </tr>
              <tr>
                <th>PIX</th>
                <td>{{ cx.vl_pix | currency }}</td>
              </tr>

              <tr>
                <th colspan="2">
                  {{ showTextDiferenca(cx.vl_diferenca) }}
                  {{ cx.vl_diferenca | currency }}
                  <span v-if="cx.obs">
                    <br />
                    Observações: {{ cx.obs }}
                  </span>
                </th>
              </tr>

              <tr>
                <th>Data Caixa</th>
                <td>{{ cx.dt_caixa | moment("DD/MM/YYYY") }}</td>
              </tr>

              <tr>
                <th>Data / Hr. Abertura</th>
                <td>
                  {{ cx.dt_cadastro | moment("DD/MM/YYYY HH:mm:ss") }} -
                  <i class="fa fa-user" /> {{ cx.usuCadastro }}
                </td>
              </tr>
              <tr>
                <th>Data / Hr. Editado</th>
                <td>
                  {{ cx.dt_alterado | moment("DD/MM/YYYY HH:mm:ss") }} -
                  <i class="fa fa-user" /> {{ cx.usuAlterado }}
                </td>
              </tr>
            </table>
            <button
              class="btn btn-secondary btn-sm mr-1"
              v-if="cx.imagem"
              @click="abrirImagem2(cx.imagem)"
              :disabled="!canEdit"
            >
              <i class="fa fa-image" /> Foto {{ " " }}
            </button>
            <button
              class="btn btn-info btn-sm mr-1"
              @click="print(cx)"
              v-if="user.nivel <= 1 || cx.vl_dinheiro > 0"
            >
              <i class="fa fa-print" /> Imprimir
            </button>
            <button
              :class="`btn btn-${
                user.nivel > 0 ? 'success' : 'warning'
              } btn-sm mr-1`"
              @click="edit(cx)"
              :disabled="!canEdit"
              v-if="user.nivel <= 1 || cx.vl_dinheiro <= 0"
            >
              <span v-if="user.nivel == 0"
                ><i class="fa fa-edit" /> Editar</span
              >
              <span v-if="user.nivel > 0"
                ><i class="fa fa-check-circle" /> Informar e Fechar</span
              >
            </button>
            <button
              class="btn btn-danger btn-sm"
              @click="del(cx)"
              :disabled="!canEdit"
              v-if="user.nivel == 0"
            >
              <i class="fa fa-trash" /> Excluir
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-add-fechamento"
      hide-footer
      :title="
        (fechamento.cod_fechamento > 0 ? 'Edtar' : 'Adicionar') + ' Fechamento'
      "
    >
      <div class="row">
        <div class="col-6">
          <label>Valor Abertura</label>
          <b-input
            type="text"
            v-money="moneyMask"
            class="text-center"
            v-focus
            v-model="fechamento.vl_aberto"
          />
        </div>
      </div>
      <div
        class="row"
        v-if="
          fechamento &&
          fechamento.cod_fechamento &&
          fechamento.cod_fechamento > 0
        "
      >
        <div class="col-6 mt-2">
          <label>Valor Dinheiro</label>
          <b-input
            type="text"
            class="text-center"
            v-money="moneyMask"
            v-focus
            v-model="fechamento.vl_dinheiro"
          />
        </div>
        <div class="col-6 mt-2">
          <label>Moedas</label>
          <b-input
            type="text"
            class="text-center"
            v-money="moneyMask"
            v-focus
            v-model="fechamento.vl_moedas"
          />
        </div>
        <div class="col-6 mt-3">
          <label>Valor Cartão</label>
          <b-input
            type="text"
            class="text-center"
            v-money="moneyMask"
            v-focus
            v-model="fechamento.vl_cartao"
          />
        </div>
        <div class="col-6 mt-3">
          <label>Sysprocard</label>
          <b-input
            type="text"
            class="text-center"
            v-money="moneyMask"
            v-focus
            v-model="fechamento.vl_sysprocard"
          />
        </div>
        <div class="col-6 mt-3">
          <label>Vales Hidromar</label>
          <b-input
            type="text"
            class="text-center"
            v-money="moneyMask"
            v-focus
            v-model="fechamento.vl_hidromar"
          />
        </div>
        <div class="col-6 mt-2">
          <label>PIX (pela chave)</label>
          <b-input
            type="text"
            class="text-center"
            v-money="moneyMask"
            v-focus
            v-model="fechamento.vl_pix"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <label>Observações</label>
          <b-input
            type="text"
            class="text-center"
            v-focus
            v-model="fechamento.obs"
          />
        </div>
        <div class="col-12 mt-2" v-if="false">
          <hr />
          <div class="card">
            <div
              :class="
                'card-body ' +
                (this.fechamento.vl_diferenca != 0
                  ? 'text-danger'
                  : 'text-success')
              "
            >
              <h3>
                <b-icon-exclamation-circle /> Diferença
                <b class="float-right text-rigth">
                  <small
                    ><small> {{ msgDiferenca }}</small> </small
                  ><br />{{
                    (this.fechamento.vl_diferenca || 0) | currencyMask
                  }}</b
                >
              </h3>
              <br />
              <br />
              <b-btn
                class="float-right"
                @click="calcularDif(fechamento)"
                variant="info"
                size="small"
                >Calcular / Re-calcular</b-btn
              >
            </div>
          </div>
        </div>
        <div class="col-12 pt-4 text-right">
          <hr />

          <b-check switch class="float-left" v-model="fechamento.print"
            >Imprimir</b-check
          >
          <b-button
            variant="primary"
            class="mr-2"
            @click="salvar"
            :disabled="loading"
          >
            <span v-if="loading">
              <b-spinner small grow size="sm" /> salvando...
            </span>
            <span v-if="!loading"> <i class="fa fa-save" /> Salvar </span>
          </b-button>
          <b-button variant="secondary" @click="cancelar"
            ><i class="fa fa-ban" /> Cancelar
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-imagem2" size="xl" hide-footer>
      <div class="text-center">
        <img
          :src="imagem"
          class="img-fluid img-responsive"
          style="width: 100%"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import CaixaLib from "../../libs/Caixas";
import axios from "../../utils/axios";
const dataDef = moment().add(parseInt(moment().format("HH")) < 8 ? -1 : 0, "d");

export default {
  props: {
    withData: {
      type: Boolean,
      default: true,
    },
    caixaTipo: {
      type: Object,
    },
    EmpresaAtiva: Object,
    dataInicial: [String, Date],
  },
  data() {
    return {
      loadingSave: false,
      lista: [],
      data: dataDef.format("YYYY-MM-DD"),
      loading: false,
      imagem: "",
      canEdit: false,
      fechamento: {
        cod_empresa:
          this.$props.EmpresaAtiva.cod_empresa ||
          this.$store.state.empresa_ativa.cod_empresa,
        cod_fechamento: 0,
        vl_aberto: "R$ 0,00",
        vl_dinheiro: "R$ 0,00",
        vl_cartao: "R$ 0,00",
        vl_moedas: "R$ 0,00",
        vl_pix: "R$ 0,00",
        vl_sysprocard: "R$ 0,00",
        vl_hidromar: "R$ 0,00",
        vl_diferenca: "R$ 0,00",
        obs: "",
        data: "",
        cod_usuario: this.$store.state.auth.user.cod_usuario,
        cod_moto: this.caixaTipo.tipo != "caixa" ? this.caixaTipo.cod : 0,
        cod_caixa: this.caixaTipo.tipo == "caixa" ? this.caixaTipo.cod : 0,
        print: true,
      },
      user: this.$store.state.auth.user,
      fields: [
        { key: "local", label: "Caixa" },
        { key: "data", label: "Hora" },
        { key: "vl_aberto", label: "Abertura" },
        { key: "vl_diferenca", label: "Diferença" },
        { key: "usu", label: "Usuário" },
        { key: "ac", label: "Opções" },
      ],
      msgDiferenca: 0,
      calculated: false,
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.data = moment(this.$props.dataInicial).format("YYYY-MM-DD");
        if (this.fields) {
          this.fields.find((x) => x.key == "usu").class =
            this.caixaTipo.tipo != "caixa" ? "d-none" : "";
        }
        if (!this.fechamento || !this.fechamento.cod_empresa) {
          this.fechamento = { cod_empresa: this.EmpresaAtiva.cod_empresa };
        }
        if (this.$props.EmpresaAtiva) {
          this.fechamento.cod_empresa = this.$props.EmpresaAtiva.cod_empresa;
        }
        this.carregar();
      },
    },
    "$props.caixaTipo": {
      deep: true,
      handler() {
        if (this.fields) {
          // console.log("caixatipo", this.caixaTipo);
          this.fields.find((x) => x.key == "local").class =
            !this.caixaTipo.cod || this.caixaTipo.cod <= 0
              ? "d-block"
              : "d-none";
          this.carregar();
        }
      },
    },
    "$props.EmpresaAtiva": {
      deep: true,
      handler() {
        if (this.EmpresaAtiva && this.EmpresaAtiva.cod_empresa) {
          if (!this.fechamento || !this.fechamento.cod_empresa) {
            this.fechamento = { cod_empresa: this.EmpresaAtiva.cod_empresa };
          }
          this.fechamento.cod_empresa = this.EmpresaAtiva.cod_empresa;
          // console.log("emppp", this.EmpresaAtiva, this.fechamento);
        }
      },
    },
    data: function () {
      this.carregar();
    },
    fechamento: {
      deep: true,
      handler() {
        //this.calcularDif(this.fechamento);
        this.calculated = false;
      },
    },
    "fechamento.vl_diferenca": {
      deep: true,
      handler() {
        this.msgDiferenca =
          this.fechamento.vl_diferenca > 0
            ? "Está passando"
            : this.fechamento.vl_diferenca < 0
            ? "Está faltando"
            : "Está correto!";
      },
    },
  },
  mounted() {
    if (this.$props.dataInicial) {
      this.data = moment(this.$props.dataInicial).format("YYYY-MM-DD");
    }

    this.carregar();
  },
  methods: {
    cancelar() {
      this.reset();
      this.$bvModal.hide("modal-add-fechamento");
      this.carregar();
      this.$emit("updateCaixas");
    },
    showTextDiferenca(vl) {
      return vl < 0
        ? "Está faltando"
        : vl > 0
        ? "Está passando"
        : "Está correto";
    },
    async calcularDif(caixa) {
      let busca = await CaixaLib.getControle(
        this.EmpresaAtiva.cod_empresa,
        moment(caixa.data).format("YYYY-MM-DD"),
        caixa.cod_caixa
      );
      // console.log("busca", busca);
      let cx = busca.find((x) => (x.cod_caixa = caixa.cod_caixa));
      if (cx) {
        console.log("calc dif ", caixa);
        caixa.vl_diferenca =
          this.formatValorReal(caixa.vl_dinheiro) +
          this.formatValorReal(caixa.vl_cartao) +
          this.formatValorReal(caixa.vl_moedas) +
          this.formatValorReal(caixa.vl_sysprocard) +
          this.formatValorReal(caixa.vl_hidromar) +
          this.formatValorReal(caixa.vl_pix) -
          (this.formatValorReal(caixa.vl_aberto) +
            cx.resumo.reduce((ret, vl) => {
              ret +=
                vl.tipo_entrega != "saidas"
                  ? (vl.PIX || 0) +
                    (vl.vl_cartao || 0) +
                    (vl.vl_dim || 0) +
                    (vl.vl_pix || 0) +
                    (vl.vl_moedas || 0) +
                    (vl.vl_hidromar || 0) +
                    (vl.vl_nao_rec || 0) +
                    (vl.vl_sysprocard || 0)
                  : 0;
              ret -= vl.tipo_entrega == "saidas" ? vl.saidas : 0;
              return ret;
            }, 0));

        if (
          caixa.vl_diferenca == "NaN" ||
          !caixa.vl_diferenca ||
          typeof caixa.vl_diferenca == "undefined"
        ) {
          caixa.vl_diferenca = 0;
        }
        console.log(caixa.vl_diferenca);
      }
      this.calculated = true;
    },
    reset() {
      this.fechamento.cod_fechamento = 0;
      this.fechamento.vl_aberto = "R$ 0,00";
      this.fechamento.vl_dinheiro = "R$ 0,00";
      this.fechamento.vl_cartao = "R$ 0,00";
      this.fechamento.vl_pix = "R$ 0,00";
      this.fechamento.vl_moedas = "R$ 0,00";
      this.fechamento.vl_sysprocard = "R$ 0,00";
      this.fechamento.vl_hidromar = "R$ 0,00";
      this.fechamento.vl_diferenca = 0;
      this.fechamento.data = moment().format("YYYY-MM-DD");
      this.fechamento.obs = "";
      this.fechamento.cod_moto = 0;
      this.fechamento.cod_usuario = this.$store.state.auth.user.cod_usuario;

      this.fechamento.cod_empresa = this.EmpresaAtiva.cod_empresa;

      // this.fechamento.cod_moto =
      //   this.caixaTipo.tipo != "caixa" ? this.caixaTipo.cod : 0;
      // this.fechamento.cod_caixa =
      //   this.caixaTipo.tipo == "caixa" ? this.caixaTipo.cod : 0;
      this.fechamento.print = true;
    },
    async print(item) {
      // console.log(item);
      this.$swal
        .fire({
          title: "Você tem certeza que deseja re-imprimir?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Re-imprimir",
          cancelButtonText: "Não",
        })
        .then(async (a) => {
          if (a.isConfirmed) {
            await CaixaLib.storeFechamento({
              fechamento: {
                cod_fechamento: item.cod_fechamento,
                cod_empresa: item.cod_empresa,
                cod_caixa: item.cod_caixa,
                print: true,
              },
            });
            this.$swal("Impressão solicitada!", "", "success");
          }
        });
    },
    async del(item) {
      this.$swal
        .fire({
          title: "Confirmação",
          text: "Tem certeza que deseja excluir?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelBUttonText: "Não",
        })
        .then(async (a) => {
          if (a.isConfirmed) {
            await CaixaLib.excluirFechamento({
              cod_fechamento: item.cod_fechamento,
            });
            await this.carregar();
            this.$emit("updateCaixas");
          }
        });
    },
    async edit(item) {
      console.log(item);
      //verificar se tem mesas em aberto
      if (item && item.salao) {
        let hasMesasAbertas = await CaixaLib.getMesas({
          cod_empresa: this.EmpresaAtiva.cod_empresa,
          dt_conta: moment(item.data).format("YYYY-MM-DD"),
          data: moment(item.data).format("YYYY-MM-DD"),
          historico: false,
          openeds: true
        });
        if(hasMesasAbertas && hasMesasAbertas.success && hasMesasAbertas.data && hasMesasAbertas.data.length > 0){
          this.$swal("Atenção", "Existem mesas em aberto. Feche-as antes de fechar o caixa.", "error");
          return;
        }
        console.log("hasMesasAberta", hasMesasAbertas)
      }
      this.fechamento.cod_fechamento = item.cod_fechamento;
      this.fechamento.data = moment(item.data).format("YYYY-MM-DD");
      this.fechamento.dt_caixa = moment(item.dt_caixa).format("YYYY-MM-DD");
      this.fechamento.dt_cadastro = item.dt_cadastro
        ? moment(item.dt_cadastro).format("YYYY-MM-DD HH:mm:ss")
        : "";
      this.fechamento.cod_usuario_cadastro = item.cod_usuario_cadastro;
      this.fechamento.cod_caixa = item.cod_caixa;
      this.fechamento.cod_moto = item.cod_moto;
      this.fechamento.obs = item.obs;
      this.fechamento.vl_aberto = this.formatFloatToReal(item.vl_aberto || 0);
      this.fechamento.vl_dinheiro = this.formatFloatToReal(
        item.vl_dinheiro || 0
      );
      this.fechamento.vl_cartao = this.formatFloatToReal(item.vl_cartao);
      this.fechamento.vl_moedas = this.formatFloatToReal(item.vl_moedas);
      this.fechamento.vl_pix = this.formatFloatToReal(item.vl_pix);
      this.fechamento.vl_sysprocard = this.formatFloatToReal(
        item.vl_sysprocard
      );
      this.fechamento.vl_hidromar = this.formatFloatToReal(item.vl_hidromar);
      this.fechamento.vl_diferenca = item.vl_diferenca || 0;

      this.$forceUpdate();
      this.$bvModal.show("modal-add-fechamento");

      //this.calcularDif(this.fechamento);
    },
    async carregar() {
      // console.log(
      //   "carregando fechamentos ---",
      //   this.$props.caixaTipo,
      //   this.data
      // );
      // console.log(
      //   "car",

      //   this.$props.caixaTipo,
      //   this.data,
      //   moment(this.data + " 00:00:00").isSame(
      //     moment(moment().format("YYYY-MM-DD 00:00:00"))
      //   )
      // );
      this.canEdit =
        this.$store.state.auth.user.nivel < 2 ||
        moment(this.data + " 00:00:00").isSame(
          moment(
            moment()
              .add(parseInt(moment().format("HH")) <= 8 ? -1 : 0, "day")
              .format("YYYY-MM-DD 00:00:00")
          )
        );
      this.lista = [];
      if (this.$props.caixaTipo && this.$props.caixaTipo.tipo) {
        this.loading = true;
        await this.$nextTick();
        try {
          //  console.log("BBBBBBBBBBBBBBBBBBBBBBBBB",     this.EmpresaAtiva.cod_empresa,
          //   this.data,
          //   this.$props.caixaTipo.tipo == "caixa"
          //     ? this.$props.caixaTipo.cod
          //     : null,
          //   this.$props.caixaTipo.tipo == "moto"
          //     ? this.$props.caixaTipo.cod
          //     : null,
          //   this.$props.caixaTipo.tipo)
          this.lista = await CaixaLib.getFechamentos(
            this.fechamento
              ? this.fechamento.cod_empresa
              : this.EmpresaAtiva
              ? this.EmpresaAtiva.cod_empresa
              : 1,
            this.data,
            this.$props.caixaTipo.tipo == "caixa"
              ? this.$props.caixaTipo.cod
              : null,
            this.$props.caixaTipo.tipo == "moto"
              ? this.$props.caixaTipo.cod
              : null,
            this.$props.caixaTipo.tipo
          );
          // console.log("list,", this.lista);
        } catch (e) {
          e;
        }
        // console.log("listaaa", this.lista);
        this.loading = false;
      }
    },
    addFechamento() {
      this.reset();
      this.$bvModal.show("modal-add-fechamento");
    },
    async salvar() {
      // console.log(this.fechamento);
      let reso = true;
      if (
        this.user.nivel > 0 &&
        this.fechamento.cod_fechamento &&
        this.fechamento.cod_fechamento > 0 &&
        this.formatValorReal(this.fechamento.vl_dinheiro) <= 0
      ) {
        this.$swal("Atenção", "Informe o valor em dinheiro.", "error");
        return;
      }
      if (
        this.user.nivel > 0 &&
        this.fechamento.cod_fechamento &&
        this.fechamento.cod_fechamento > 0 &&
        this.formatValorReal(this.fechamento.vl_dinheiro) > 0
      ) {
        reso = await this.$swal({
          title: "ATENÇÃO",
          text: "Você está FECHANDO o caixa! Não será mais possível editar os valores inseridos. Deseja continuar mesmo assim?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não.",
          confirmButtonText: "Sim. FECHAR CAIXA!",
        }).then((resp) => {
          return resp.isConfirmed;
        });
      }
      // console.log("RESP", reso);
      if (!reso) return;

      this.calcularDif(this.fechamento);

      this.loading = true;

      await this.$nextTick();

      await this.calcularDif(this.fechamento);

      let fecha = Object.assign({}, { ...this.fechamento });

      fecha.data = this.data;

      if (!fecha.dt_caixa) {
        fecha.dt_caixa = moment(
          this.dataInicial && this.dataInicial != ""
            ? this.dataInicial
            : this.data
        ).format("YYYY-MM-DD");
      }

      if (!fecha.dt_cadastro) {
        fecha.dt_cadastro = moment().format("YYYY-MM-DD HH:mm:ss");
        fecha.cod_usuario_cadastro = this.user.cod_usuario;
      }

      if (fecha.cod_fechamento && fecha.cod_fechamento > 0) {
        fecha.dt_alterado = moment().format("YYYY-MM-DD HH:mm:ss");
        fecha.cod_usuario_alterado = this.user.cod_usuario;
      }

      if (this.caixaTipo.tipo == "caixa") fecha.cod_caixa = this.caixaTipo.cod;
      else fecha.cod_moto = this.caixaTipo.cod;

      fecha.vl_aberto = await this.formatValorReal(this.fechamento.vl_aberto);
      fecha.vl_dinheiro = await this.formatValorReal(
        this.fechamento.vl_dinheiro
      );
      fecha.vl_cartao = await this.formatValorReal(this.fechamento.vl_cartao);
      fecha.vl_moedas = await this.formatValorReal(this.fechamento.vl_moedas);
      fecha.vl_pix = await this.formatValorReal(this.fechamento.vl_pix);
      fecha.vl_sysprocard = await this.formatValorReal(
        this.fechamento.vl_sysprocard
      );
      fecha.vl_hidromar = await this.formatValorReal(
        this.fechamento.vl_hidromar
      );
      fecha.vl_diferenca = this.fechamento.vl_diferenca;

      // console.log("fehca", fecha);
      // // let fechamento = true;
      let fechamento = await CaixaLib.storeFechamento({
        fechamento: fecha,
      });
      // console.log(fechamento);
      try {
        if (fechamento) {
          if (fechamento.success) {
            this.$swal
              .fire({
                title: "Fechamento salvo com sucesso!",
                icon: "success",
              })
              .then(() => {
                // this.reset();
                this.$bvModal.hide("modal-add-fechamento");
                this.carregar();
                //this.$emit("updateCaixas");
              });
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      await this.$nextTick();
    },
    abrirImagem2(imagem) {
      this.imagem =
        axios.defaults.baseURL +
        "/download/" +
        imagem +
        "?token=" +
        this.user.token;
      if (imagem && imagem != "") {
        this.$bvModal.show("modal-imagem2");
      }
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped>
@import "@/assets/scss/caixas.scss";
</style>
