<template>
  <div>
    <div>
      <div v-if="loading"><b-spinner /> carregando...</div>
      <div class="caixa p-1" v-if="!loading && !isMoto">
        <div class="row">
          <div class="col-8">
            <h3>
              <i class="fa fa-cash-register mr-1" />
              {{ caixa && caixa.nome ? caixa.nome : "" }} -
              <small
                ><b-icon-calendar class="mr-1" />
                {{ dataSel | moment("DD/MM/YYYY") }}</small
              >
            </h3>
            <div class="" v-if="caixa">
              <label
                >Fundo / Troco <b-spinner v-if="caixa && caixa.loading" small
              /></label>
              <!-- <b-input
                v-model="caixa.vl_aberto"
                class="text-center"
                v-money="moneyMask"
                @blur="changeValor(caixa)"
              /> --><br />
              <h4 class="font-weight-bold text-info">
                {{ vl_fundo | currencyMask }}
                <div class="d-flex w-100 justify-content-center">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixaProp.data"
                    :referencia="'Entrega de Fundo p/ Funcionário Responsável'"
                    :valor="vl_fundo"
                    buttonText="Entr. Funcionário"
                  />
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixaProp.data"
                    :referencia="'Devolução de Troco p/ Cofre'"
                    :valor="vl_fundo"
                    buttonText="Devol. Cofre"
                    buttonClass="bg-info"
                  />
                </div>
              </h4>
            </div>
          </div>
          <div class="col-4 pt-2 h-100 pt-4">
            <button
              class="btn btn-success btn-sm h-100 w-75"
              @click="marcarConferido()"
              v-if="!hasConferido"
            >
              <i class="fa fa-check"></i><br />Marcar Conferido
            </button>
            <div v-if="hasConferido" class="text-success font-weight-bold">
              <i class="fa fa-check"></i><br />
              CAIXA CONFERIDO
            </div>
            <button
              class="btn btn-lg btn-sm btn-primary w-75"
              :disabled="pedidosLoading"
              @click="abrirDetalhes"
            >
              <div v-if="!pedidosLoading">
                <i class="fa fa-clipboard-list" /> <br />
                VER
                {{ isMoto ? "ENTREGAS" : "PEDIDOS" }}
              </div>
              <div v-if="pedidosLoading"><b-spinner /> carregando...</div>
            </button>
          </div>
        </div>
        <div class="text-left mt-2">
          <table class="table table-sm table-hover" style="font-size: 11px">
            <thead>
              <tr>
                <th>Pagamento</th>
                <th class="text-center">Total</th>
                <th class="text-center">Informado</th>
                <th class="text-center">
                  Informado
                  <div><small>sem troco</small></div>
                </th>
                <th class="text-center">Diferença</th>
                <th class="text-center"><i class="fa fa-university" /></th>
              </tr>
            </thead>
            <tbody v-if="caixa && caixa.resumo">
              <tr>
                <td>
                  <label><i class="fa fa-money-bill-alt" /> Dinheiro</label>
                </td>
                <td class="text-center">
                  {{ vl(caixa.resumo, "", "vl_dim", true) | currency }}
                </td>
                <td class="text-center">
                  {{
                    getFechamentosCaixa(caixa.cod_caixa).vl_dinheiro | currency
                  }}
                </td>
                <td class="text-center">
                  {{
                    (getFechamentosCaixa(caixa.cod_caixa).vl_dinheiro -
                      vl_fundo)
                      | currency
                  }}
                </td>
                <td
                  class="text-center"
                  :class="{
                    'text-danger':
                      getFechamentosCaixa(caixa.cod_caixa).vl_dinheiro -
                        vl(caixa.resumo, '', 'vl_dim', true) <
                      0,
                  }"
                >
                  {{
                    (getFechamentosCaixa(caixa.cod_caixa).vl_dinheiro -
                      vl(caixa.resumo, "", "vl_dim", true))
                      | currency
                  }}
                </td>
                <td class="d-flex">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :valor="
                      getFechamentosCaixa(caixa.cod_caixa).vl_dinheiro -
                      vl_fundo
                    "
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Dinheiro'"
                  />
                  <div class="ml-1">
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="vl_fundo"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="caixa.data"
                      :referencia="'Troco'"
                    />
                  </div>
                </td>
              </tr>

              <tr class="bg-light">
                <td>
                  <label
                    ><i class="fa fa-credit-card" /> Cartão + PIX
                    <i class="fa fa-qrcode"
                  /></label>
                </td>

                <td class="text-center">
                  {{
                    ((vl(caixa.resumo, "", "vl_cartao", true) || 0) +
                      (vl(caixa.resumo, "", "PIX", true) || 0))
                      | currency
                  }}
                </td>

                <td class="text-center">
                  {{
                    ((getFechamentosCaixa(caixa.cod_caixa).vl_cartao || 0) +
                      (getFechamentosCaixa(caixa.cod_caixa).vl_pix || 0))
                      | currency
                  }}
                </td>
                <td></td>

                <td
                  class="text-center"
                  :class="{
                    'text-danger':
                      getFechamentosCaixa(caixa.cod_caixa).vl_cartao +
                        getFechamentosCaixa(caixa.cod_caixa).vl_pix -
                        vl(caixa.resumo, '', 'vl_cartao', true) -
                        vl(caixa.resumo, '', 'PIX', true) <
                      0,
                  }"
                >
                  {{
                    (getFechamentosCaixa(caixa.cod_caixa).vl_cartao +
                      getFechamentosCaixa(caixa.cod_caixa).vl_pix -
                      vl(caixa.resumo, "", "vl_cartao", true) -
                      vl(caixa.resumo, "", "PIX", true))
                      | currency
                  }}
                </td>
                <td class="d-flex">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Cartão | PIX'"
                    :valor="
                      getFechamentosCaixa(caixa.cod_caixa).vl_cartao ||
                      (vl(caixa.resumo, '', 'vl_cartao', true) || 0) +
                        (vl(caixa.resumo, '', 'PIX', true) || 0)
                    "
                  />
                  <caixa-lancamento-banco
                    buttonClass="ml-1  bg-info"
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Tarifa de Cartão | PIX'"
                    :valor="0"
                  />
                </td>
              </tr>

              <tr>
                <td class="pl-3">
                  <small
                    ><label
                      ><i class="fa fa-credit-card" /> Cartão</label
                    ></small
                  >
                </td>

                <td class="text-center">
                  <small>{{
                    vl(caixa.resumo, "", "vl_cartao", true) | currency
                  }}</small>
                </td>
                <td class="text-center">
                  <small>
                    {{
                      (getFechamentosCaixa(caixa.cod_caixa).vl_cartao || 0)
                        | currency
                    }}
                  </small>
                </td>
                <td></td>
                <td
                  class="text-center"
                  :class="{
                    'text-danger':
                      (vl(caixa.resumo, '', 'vl_cartao', true) || 0) -
                        (getFechamentosCaixa(caixa.cod_caixa).vl_cartao || 0) <
                      0,
                  }"
                >
                  <small>
                    {{
                      ((vl(caixa.resumo, "", "vl_cartao", true) || 0) -
                        (getFechamentosCaixa(caixa.cod_caixa).vl_cartao || 0))
                        | currency
                    }}
                  </small>
                </td>
                <td class="d-flex">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Cartão'"
                  />
                  <caixa-lancamento-banco
                    buttonClass="ml-1  bg-info"
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Tarifa de Cartão'"
                    :valor="0"
                  />
                </td>
              </tr>

              <tr>
                <td class="pl-3">
                  <small
                    ><label><i class="fa fa-qrcode" /> PIX</label></small
                  >
                  <b-btn
                    size="sm"
                    variant="primary"
                    class="ml-3"
                    @click="abrirListaPix"
                    ><b-icon-list-check /> Lista</b-btn
                  >
                </td>

                <td class="text-center">
                  <small>{{
                    vl(caixa.resumo, "", "PIX", true) | currency
                  }}</small>
                </td>
                <td class="text-center">
                  <small>
                    {{ getFechamentosCaixa(caixa.cod_caixa).vl_pix | currency }}
                  </small>
                </td>
                <td></td>

                <td
                  class="text-center"
                  :class="{
                    'text-danger':
                      (vl(caixa.resumo, '', 'PIX', true) || 0) -
                        (getFechamentosCaixa(caixa.cod_caixa).vl_pix || 0) <
                      0,
                  }"
                >
                  <small>
                    {{
                      ((vl(caixa.resumo, "", "PIX", true) || 0) -
                        (getFechamentosCaixa(caixa.cod_caixa).vl_pix || 0))
                        | currency
                    }}
                  </small>
                </td>
                <td>
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'PIX'"
                    :valor="getFechamentosCaixa(caixa.cod_caixa).vl_pix"
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label><i class="fa fa-globe-americas" /> Online</label>
                </td>

                <td class="text-center">
                  {{ vl(caixa.resumo, "", "online", true) | currency }}
                </td>
                <td />
                <td />
                <td></td>

                <td>
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Online'"
                    :valor="vl(caixa.resumo, '', 'online', true)"
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label><i class="fa fa-wallet" /> Vale</label>
                </td>

                <td class="text-center">
                  {{ vl(caixa.resumo, "", "vale", true) | currency }}
                </td>
                <td />
                <td />
                <td></td>

                <td>
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Vale'"
                    :valor="getFechamentosCaixa(caixa.cod_caixa).vale"
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label
                    ><i class="far fa-address-card"></i> Vale Refeição /
                    Sysprocard</label
                  >
                </td>

                <td class="text-center">
                  {{ vl(caixa.resumo, "", "VR", true) | currency }}
                </td>
                <td class="text-center">
                  {{
                    getFechamentosCaixa(caixa.cod_caixa).vl_sysprocard
                      | currency
                  }}
                </td>
                <td></td>

                <td
                  class="text-center"
                  :class="{
                    'text-danger':
                      vl(caixa.resumo, '', 'VR', true) -
                        getFechamentosCaixa(caixa.cod_caixa).vl_sysprocard <
                      0,
                  }"
                >
                  {{
                    (vl(caixa.resumo, "", "VR", true) -
                      getFechamentosCaixa(caixa.cod_caixa).vl_sysprocard)
                      | currency
                  }}
                </td>
                <td class="d-flex">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'VR'"
                    :valor="getFechamentosCaixa(caixa.cod_caixa).vl_sysprocard"
                  />
                  <div class="ml-1">
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="caixa.data"
                      :referencia="'Tarifa VR'"
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <label><i class="fa fa-star-of-life" /> Só Entregar</label>
                </td>

                <td class="text-center">
                  {{ vl(caixa.resumo, "", "entregar", true) | currency }}
                </td>
                <td />
                <td />
                <td></td>

                <td>
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'SoEntregar'"
                  />
                </td>
              </tr>

              <tr class="text-danger">
                <td>
                  <label><i class="fa fa-hand-holding-usd" /> Saídas</label>
                </td>

                <td class="text-center">
                  {{ vl(caixa.resumo, "", "saidas", true) | currency }}
                </td>
                <td />
                <td />
                <td></td>

                <td>
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Saidas'"
                  />
                </td>
              </tr>

              <tr class="text-danger">
                <td>
                  <label
                    ><i class="fa fa-exclamation-triangle" /> Problema</label
                  >
                </td>

                <td class="text-center">
                  Dinheiro:
                  {{ vl(caixa.resumo, "", "erro_vl_dim", true) | currency }}
                  <br />
                  Cartão:
                  {{ vl(caixa.resumo, "", "erro_vl_cartao", true) | currency }}
                </td>
                <td />
                <td />
                <td />
                <td></td>
              </tr>

              <tr class="text-white bg-secondary">
                <td>
                  <label><i class="fa fa fa-cash-register" /> Total</label>
                </td>

                <td class="text-center">
                  {{ vl(caixa.resumo, "TOTAL", "TOTAL", true) | currency }}
                </td>
                <td />
                <td></td>

                <td />
                <td class="text-right">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Total'"
                  />
                </td>
              </tr>
              <tr
                class="font-weight-bold text-white bg-danger"
                v-if="vl(caixa.resumo, '', 'vl_nao_rec', true) > 0"
              >
                <td>
                  <label><i class="fa fa-money-bill-alt" /> NÃO RECEBIDO</label>
                </td>
                <td class="text-center">
                  {{ vl(caixa.resumo, "", "vl_nao_rec", true) | currency }}
                </td>
                <td>
                  <b-btn small size="sm" @click="openLancamentos(caixa.resumo)"
                    >Ver Lançamentos</b-btn
                  >
                </td>
                <td></td>
                <td></td>

                <td>
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Não Recebido'"
                  />
                </td>
              </tr>
              <tr
                class="text- bg-warning"
                v-if="vl(caixa.resumo, '', 'vl_nao_rec', true) > 0"
              >
                <td>
                  <label
                    ><i class="fa fa fa-cash-register" /> Total + NÃO
                    RECEBIDOS</label
                  >
                </td>

                <td class="text-center">
                  {{
                    (vl(caixa.resumo, "TOTAL", "TOTAL", true) +
                      vl(caixa.resumo, "", "vl_nao_rec", true))
                      | currency
                  }}
                </td>
                <td />

                <td />
                <td />
                <td class="text-right">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'Total+NaoRecebido'"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-if="caixa && caixa.resumo">
              <tr class="text-white bg-primary">
                <td colspan="">
                  <label
                    ><i class="fa fa fa-cash-register" /> Total Dinheiro</label
                  >
                </td>

                <td class="text-center">
                  {{
                    vl(
                      caixa.resumo,
                      "TOTALD",
                      "TOTALD",
                      false,
                      caixa.vl_aberto || 0
                    ) | currency
                  }}
                </td>
                <td />
                <td />
                <td></td>

                <td class="text-right">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixa.data"
                    :referencia="'TotalDinheiro'"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row" v-if="caixa && caixa.resumo">
            <div class="col-12 col-sm-3 pr-1">
              <b-card class="text-center">
                <small>Fundo / Abertura</small>
                <h4>{{ vl_fundo | currencyMask }}</h4>
              </b-card>
            </div>
            <div class="col-12 col-sm-3 pl-1 pr-1">
              <b-card class="text-center">
                <small>Total Vendido</small>
                <h4>
                  {{ vl(caixa.resumo, "TOTAL", "TOTAL", true) | currency }}
                </h4>
              </b-card>
            </div>
            <div class="col-12 col-sm-3 pl-1 pr-1">
              <b-card class="text-center">
                <small>Total Informado</small>
                <h4>
                  {{
                    ((getFechamentosCaixa(caixa.cod_caixa, null).vl_dinheiro ||
                      0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_moedas ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_cartao ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_pix || 0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_hidromar ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null)
                        .vl_sysprocard || 0))
                      | currency
                  }}
                </h4>
              </b-card>
            </div>
            <div class="col-12 col-sm-3 pl-1">
              <b-card
                class="text-center"
                :class="
                  vl(caixa.resumo, 'TOTAL', 'TOTAL', true) +
                    vl_fundo -
                    ((getFechamentosCaixa(caixa.cod_caixa, null).vl_dinheiro ||
                      0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_moedas ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_cartao ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_pix || 0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_hidromar ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null)
                        .vl_sysprocard || 0)) >
                  0
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                <small>Diferença</small>
                <h4>
                  {{
                    ((getFechamentosCaixa(caixa.cod_caixa, null).vl_dinheiro ||
                      0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_moedas ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_cartao ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_pix || 0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null).vl_hidromar ||
                        0) +
                      (getFechamentosCaixa(caixa.cod_caixa, null)
                        .vl_sysprocard || 0) -
                      vl(caixa.resumo, "TOTAL", "TOTAL", true) -
                      vl_fundo)
                      | currency
                  }}
                </h4>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <!------------CAIXA DE MOTOS-------------------->
      <div class="caixa" v-if="!loading && isMoto">
        <div>
          <div class="row" v-if="!showOnly">
            <div class="col-8" v-if="moto">
              <h3><i class="fa fa-motorcycle" /> {{ moto.moto }}</h3>

              <label
                >Fundo / Troco <b-spinner v-if="moto.loading" small
              /></label>
 
              <h4 class="font-weight-bold text-info">
                {{ moto.valor_entrada | currencyMask }}

                <div class="d-flex w-100 justify-content-center">
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixaProp.data"
                    :referencia="'MOTO - Entrega de Fundo p/ Funcionário Responsável'"
                    :valor="moto.valor_entrada"
                    buttonText="Entr. Funcionário"
                    tipo="MOTO"
                  />
                  <caixa-lancamento-banco
                    :carteira_id="caixaProp.carteira_id"
                    :cod_empresa="caixaProp.cod_empresa"
                    :lojaNome="empresaSelecionada.nome"
                    :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                    :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                    :data="caixaProp.data"
                    :referencia="'MOTO - Devolução de Troco p/ Cofre'"
                    :valor="moto.valor_entrada"
                    buttonText="Devol. Cofre"
                    buttonClass="bg-info"
                    tipo="MOTO"
                  />

                  <b-btn
                    variant="danger"
                    @click="delMoto"
                    size="sm"
                    small
                    v-if="caixaProp.cod_entrada"
                    >Excluir Moto</b-btn
                  >
                </div>
              </h4>
            </div>
            <div class="col-4 pt-2 h-100 pt-4">
              <button
                class="btn btn-success btn-sm h-100"
                @click="marcarConferido()"
                v-if="!hasConferido"
              >
                <i class="fa fa-check"></i><br />Marcar Conferido
              </button>
              <div v-if="hasConferido" class="text-success font-weight-bold">
                <i class="fa fa-check"></i><br />
                CAIXA CONFERIDO
              </div>
              <button
                class="btn btn-lg btn-sm btn-primary w-75"
                :disabled="pedidosLoading"
                @click="abrirDetalhes"
              >
                <div v-if="!pedidosLoading">
                  <i class="fa fa-clipboard-list" /> <br />
                  VER
                  {{ isMoto ? "ENTREGAS" : "PEDIDOS" }}
                </div>
                <div v-if="pedidosLoading"><b-spinner /> carregando...</div>
              </button>
            </div>
          </div>

          <div class="text-left" v-if="moto">
            <table class="table tbResumoCaixas">
              <thead>
                <tr>
                  <th>Pagamento</th>

                  <th class="text-center">Total</th>
                  <th class="text-center">Informado</th>
                  <th class="text-center">
                    Informado
                    <div><small>sem troco</small></div>
                  </th>
                  <th class="text-center">Diferença</th>
                  <th class="text-center">Img.</th>
                  <th class="text-center"><i class="fa fa-university" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label><i class="fa fa-money-bill-alt" /> Dinheiro</label>
                  </td>

                  <td class="text-center">
                    {{ moto.vl_dim | currency }}
                  </td>
                  <td class="text-center">
                    {{
                      getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro
                        | currency
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      (getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro -
                        moto.valor_entrada)
                        | currency
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      (getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro -
                        moto.vl_dim)
                        | currency
                    }}
                  </td>
                  <td class="text-center" />
                  <td class="d-flex">
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="
                        getFechamentosCaixa(moto.cod_caixa).vl_dinheiro -
                        moto.valor_entrada
                      "
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Dinheiro'"
                      :tipo="'MOTO'"
                    />
                    <div class="ml-1">
                      <caixa-lancamento-banco
                        :carteira_id="caixaProp.carteira_id"
                        :valor="moto.valor_entrada"
                        :cod_empresa="caixaProp.cod_empresa"
                        :lojaNome="empresaSelecionada.nome"
                        :cod_caixa="
                          caixaProp.cod_caixa > 0 ? caixaProp.nome : 0
                        "
                        :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                        :data="moto.data"
                        :referencia="'MOTO - TROCO'"
                        :tipo="'MOTO'"
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label><i class="fa fa-credit-card" /> Cartão</label>
                  </td>

                  <td class="text-center">
                    {{ moto.vl_cartao | currency }}
                  </td>

                  <td class="text-center">
                    {{
                      getFechamentosCaixa(null, moto.cod_moto).vl_cartao
                        | currency
                    }}
                  </td>
                  <td></td>

                  <td class="text-center">
                    {{
                      ((moto.vl_cartao || 0) -
                        getFechamentosCaixa(null, moto.cod_moto).vl_cartao)
                        | currency
                    }}
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      @click="
                        abrirImagem(
                          getFechamentosCaixa(null, moto.cod_moto).imagem
                        )
                      "
                      v-if="getFechamentosCaixa(null, moto.cod_moto).imagem"
                    >
                      <i class="fa fa-image" />
                    </button>
                  </td>
                  <td class="d-flex">
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="
                        getFechamentosCaixa(null, moto.cod_moto).vl_cartao
                      "
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Cartão'"
                      :tipo="'MOTO'"
                    />

                    <caixa-lancamento-banco
                      buttonClass="ml-1  bg-info"
                      :carteira_id="caixaProp.carteira_id"
                      :valor="
                        getFechamentosCaixa(null, moto.cod_moto).vl_cartao
                      "
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Tarifa de Cartão'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>

                <tr
                  class="font-weight-bold bg-info text-white"
                  :class="{
                    'bg-danger':
                      getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro -
                        (moto.vl_cartao + moto.vl_dim) <
                      0,
                    'bg-success':
                      getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro -
                        (moto.vl_cartao + moto.vl_dim) ==
                      0,
                  }"
                >
                  <td>Sub-Total</td>
                  <td class="text-center">
                    {{ (moto.vl_cartao + moto.vl_dim) | currency }}
                  </td>
                  <td class="text-center">
                    {{
                      (getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro)
                        | currency
                    }}
                  </td>
                  <td></td>

                  <td class="text-center">
                    {{
                      (getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro -
                        (moto.vl_cartao + moto.vl_dim))
                        | currency
                    }}
                  </td>
                  <td></td>
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="
                        getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro
                      "
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - SubTotal'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>
                <!-- MOTO SO ENTREGAR -->
                <tr>
                  <td>
                    <label><i class="fa fa-asterisk" /> Só entregar</label>
                  </td>

                  <td class="text-center">
                    {{ moto.vl_so_entregar | currency }}
                  </td>

                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      @click="
                        abrirImagem(
                          getFechamentosCaixa(null, moto.cod_moto).imagem
                        )
                      "
                      v-if="getFechamentosCaixa(null, moto.cod_moto).imagem"
                    >
                      <i class="fa fa-image" />
                    </button>
                  </td>
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="moto.vl_so_entregar"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Só Entregar'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>
                <!--MOTO ONLINE-->
                <tr>
                  <td>
                    <label><i class="fa fa-globe-americas" /> Online</label>
                  </td>

                  <td class="text-center">
                    {{ moto.vl_online | currency }}
                  </td>

                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      @click="
                        abrirImagem(
                          getFechamentosCaixa(null, moto.cod_moto).imagem
                        )
                      "
                      v-if="getFechamentosCaixa(null, moto.cod_moto).imagem"
                    >
                      <i class="fa fa-image" />
                    </button>
                  </td>
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="moto.vl_online"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Online'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>

                <tr class="font-weight-bold bg-info text-white bg-info">
                  <td><small>Sub-Total + (Só entrega + Online)</small></td>
                  <td class="text-center">
                    {{
                      (moto.vl_cartao +
                        moto.vl_dim +
                        moto.vl_online +
                        moto.vl_so_entregar)
                        | currency
                    }}
                  </td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td></td>
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="
                        moto.vl_cartao +
                        moto.vl_dim +
                        moto.vl_online +
                        moto.vl_so_entregar
                      "
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - SoEntregar+Online'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>
                <!--SAIDAS MOTO-->
                <tr class="text-danger">
                  <td>
                    <label><i class="fa fa-hand-holding-usd" /> Saídas</label>
                  </td>

                  <td class="text-center">
                    {{ moto.vl_saidas | currency }}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="moto.vl_saidas"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Saidas'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>
                <!--PROBLEMAS MOTO-->
                <tr class="text-danger">
                  <td>
                    <label
                      ><i class="fa fa-exclamation-triangle" /> Problema</label
                    >
                  </td>

                  <td class="text-right">
                    Dinheiro:
                    {{
                      moto && moto.problema
                        ? moto.problema.vl_dim
                        : 0 | currency
                    }}
                    <br />
                    Cartão:
                    {{
                      moto && moto.problema
                        ? moto.problema.vl_car
                        : 0 | currency
                    }}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="vl(moto.resumo, '', 'saidas', true)"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Problema'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    Entregas
                    <div class="pl-2 text-danger"></div>
                  </td>
                  <td class="text-center">
                    <div
                      v-for="v of moto.porValor"
                      :key="'pvl_' + moto.porValor.indexOf(v)"
                    >
                      {{ v.qtd }} x {{ v.vl_entrega | currency }}<br />
                    </div>
                    <div class="pl-2 text-danger" v-if="moto && moto.problema">
                      <small>Com Problema: </small>
                      <small>{{ moto.problema.qt_prob || 0 }}</small>
                    </div>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>Valor Pagamento</td>
                  <td class="text-center">
                    {{ moto.total_entregas | currency }}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="moto.total_entregas"
                      :cod_empresa="caixaProp.cod_empresa"
                      :lojaNome="empresaSelecionada.nome"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - Entregas'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="text-white bg-primary">
                  <td>
                    <label
                      ><i class="fa fa fa-cash-register" /> Total
                      Dinheiro</label
                    >
                  </td>

                  <td class="text-center" colspan="1">
                    {{ moto.vl_total | currency }}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <caixa-lancamento-banco
                      :carteira_id="caixaProp.carteira_id"
                      :valor="getFechamentosCaixa(moto.cod_caixa).vl_dinheiro"
                      :cod_caixa="caixaProp.cod_caixa > 0 ? caixaProp.nome : 0"
                      :cod_moto="caixaProp.cod_moto > 0 ? caixaProp.moto : 0"
                      :data="moto.data"
                      :referencia="'MOTO - TotalDinheiro'"
                      :tipo="'MOTO'"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-12 col-sm-3 pr-1">
                <b-card class="text-center">
                  <small>Fundo / Abertura</small>
                  <h4>{{ vl_fundo | currencyMask }}</h4>
                </b-card>
              </div>
              <div class="col-12 col-sm-3 pl-1 pr-1">
                <b-card class="text-center">
                  <small>Total Vendido</small>

                  <h4>
                    {{
                      (moto.vl_cartao + moto.vl_dim + moto.vl_online) | currency
                    }}
                  </h4>
                </b-card>
              </div>
              <div class="col-12 col-sm-3 pl-1 pr-1">
                <b-card class="text-center">
                  <small>Total Informado</small>
                  <h4>
                    {{
                      (getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro +
                        getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_pix +
                        getFechamentosCaixa(null, moto.cod_moto).vl_hidromar +
                        getFechamentosCaixa(null, moto.cod_moto).vl_sysprocard)
                        | currency
                    }}
                  </h4>
                </b-card>
              </div>
              <div class="col-12 col-sm-3 pl-1">
                <b-card
                  class="text-center"
                  :class="
                    moto.vl_cartao +
                      moto.vl_dim +
                      moto.vl_online +
                      vl_fundo -
                      (getFechamentosCaixa(null, moto.cod_moto).vl_dinheiro +
                        getFechamentosCaixa(null, moto.cod_moto).vl_cartao +
                        getFechamentosCaixa(null, moto.cod_moto).vl_pix +
                        getFechamentosCaixa(null, moto.cod_moto).vl_hidromar +
                        getFechamentosCaixa(null, moto.cod_moto)
                          .vl_sysprocard) >
                    0
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  <small>Diferença</small>
                  <h4>
                    {{
                      ((moto.vl_cartao || 0) +
                        (moto.vl_dim || 0) +
                        (moto.vl_online || 0) +
                        (moto.vl_so_entregar || 0) +
                        (vl_fundo || 0) -
                        ((getFechamentosCaixa(null, moto.cod_moto)
                          .vl_dinheiro || 0) +
                          (getFechamentosCaixa(null, moto.cod_moto).vl_cartao ||
                            0) +
                          (getFechamentosCaixa(null, moto.cod_moto).vl_pix ||
                            0) +
                          (getFechamentosCaixa(null, moto.cod_moto)
                            .vl_hidromar || 0) +
                          (getFechamentosCaixa(null, moto.cod_moto)
                            .vl_sysprocard || 0)))
                        | currency
                    }}
                  </h4>
                </b-card>
              </div>
            </div>
          </div>
        </div>

        <b-modal id="modal-imagem" size="xl" hide-footer>
          <div class="text-center">
            <img
              :src="imagem"
              class="img-fluid img-responsive"
              style="width: 100%"
            />
          </div>
        </b-modal>
      </div>

      <div class="fontMin">
        <b-modal
          size="xl"
          id="modal-pedidos-entregas"
          hide-footer
          :title="`LISTA DE ${
            isMoto
              ? 'ENTREGAS'
              : caixa &&
                caixa.salao &&
                typeof caixa.salao !== 'undefined' &&
                caixa.salao === 1
              ? 'CONTAS DE MESA'
              : 'PEDIDOS'
          }`"
          style="font-size: 11px"
        >
          <caixa-lista-pedidos
            :pedidos="pedidos"
            :caixa="caixaProp"
            @atualizarPedidos="
              () => {
                abrirDetalhes();
                atualizarParent();
              }
            "
            @close="
              () => {
                atualizarParent();
                carregar();
                $bvModal.hide('modal-pedidos-entregas');
              }
            "
          />
        </b-modal>
      </div>

      <caixa-conferencia
        v-if="!showOnly"
        :caixaProp="caixaProp"
        :codCaixa="caixaProp.cod_caixa > 0 ? caixaProp.cod_caixa : 0"
        :codMoto="caixaProp.cod_moto > 0 ? caixaProp.cod_moto : 0"
        :dataInicial="caixaProp.data"
        :codEmpresa="caixaProp.cod_empresa"
        ref="caixaConferencia"
        @setConferido="setConferido"
        @carregar="atualizarParent"
      />

      <hr />

      <div class="row">
        <div class="col-12 text-left">
          <h3><b-icon-upload class="mr-1" /> Anexos</h3>
        </div>
        <div class="col-12 mt-2" v-if="caixaProp && caixaProp.anexos">
          <FileUpload :enableTitle="false" v-model="caixaProp.anexos" />
        </div>
      </div>
    </div>
    <b-modal
      id="modal-bancario"
      no-enforce-focus
      hide-footer
      title="Movimento Bancário"
    >
      <movimento-form :startObj="movObj" @cancelar="closeBancario" />
    </b-modal>
    <b-modal id="modal-lista-pix" title="Lista PIX" size="lg" hide-footer>
      <relatorio-pix :listaPix="listaPix" />
    </b-modal>
    <b-modal id="modal-nao-recebidos" hide-footer title="Não recebidos">
      <b-table :items="itensNaoRecebidos">
        <template #cell(falta)="row">
          {{ row.value | currency }}
        </template>
        <template #cell(total)="row">
          {{ row.value | currency }}
        </template>
        <template #cell(pago)="row">
          {{ row.value | currency }}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import CaixaLib from "../../libs/Caixas";
import PedidoLib from "../../libs/Pedido";
import axios from "../../utils/axios";
import moment from "moment-timezone";
import CaixaConferencia from "./CaixaConferencia.vue";
import MovimentoForm from "../../components/Financeiro/Bancario/MovimentoForm.vue";
import CaixaListaPedidos from "./CaixaListaPedidos.vue";
import RelatorioPix from "../../components/Caixas/RelatorioPix.vue";
import CaixaLancamentoBanco from "./CaixaLancamentoBanco.vue";
import FileUpload from "../../components/common/FileUpload.vue";

export default {
  components: {
    CaixaConferencia,
    MovimentoForm,
    CaixaListaPedidos,
    RelatorioPix,
    CaixaLancamentoBanco,
    FileUpload,
  },
  props: {
    caixaProp: Object,
    dataSel: String,
    isMoto: Boolean,
    showOnly: {
      type: Boolean,
      default: false,
    },
    empresaSelecionada: Object,
  },
  data() {
    return {
      informados: [],
      loading: false,
      caixa: null,
      moto: null,
      imagem: "",
      user: this.$store.state.auth.user,
      pedidosLoading: false,
      pedidos: [],
      hasConferido: false,
      filtro: "",
      data: moment().format("YYYY-MM-DD"),
      movObj: {},
      vl_fundo: 0,
      listaPix: [],
      itensNaoRecebidos: [],
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        // console.log("caixa prop", this.caixaProp, this.dataSel);

        this.carregar();
        if (this.caixaProp.cod_controle && this.caixaProp.anexos) {
          CaixaLib.storeCaixasAnexos({
            cod_controle: this.caixaProp.cod_controle,
            anexos: this.caixaProp.anexos,
          });
        }
      },
    },
    // dataSel: function () {
    //   this.carregar();
    // },
  },
  mounted() {
    //   this.carregar();

    if (!this.caixaProp.anexos) {
      this.caixaProp.anexos = [];
    }
    this.carregar();
    // console.log("empes", this.$store.state.empresas);
  },
  methods: {
    openLancamentos(items) {
      this.itensNaoRecebidos = items
        .filter((x) => x.itens_nao_rec && x.itens_nao_rec.length > 0)
        .reduce((ret, vl) => {
          ret.push(
            ...vl.itens_nao_rec.map((x) => ({ ...x, falta: x.total - x.pago }))
          );
          return ret;
        }, []);
      this.$bvModal.show("modal-nao-recebidos");
    },
    abrirListaPix() {
      this.listaPix = this.caixaProp.listaPIX.map((ret) => ({
        ...ret.dados_pagamento.PIX,
        venda: ret,
      }));
      // console.log("asda", this.listaPix);
      this.$bvModal.show("modal-lista-pix");
    },
    closeBancario() {
      this.$bvModal.hide("modal-bancario");
    },
    setConferido(conf) {
      this.hasConferido = conf;
    },
    atualizarParent() {
      console.log("atualiza parent");
      this.$emit("carregar");
      this.carregar();
    },
    marcarConferido() {
      this.$swal
        .fire({
          title: "Marcar como conferido?",
          text: "Tem certeza que deseja marcar como conferido?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim. Marcar!",
          cancelButtonText: "Não",
        })
        .then(async (c) => {
          if (c.isConfirmed) {
            await this.$refs.caixaConferencia.addOK();
            this.movObj = {
              valor: this.moto
                ? this.moto.vl_total
                : this.vl(
                    this.caixa.resumo,
                    "TOTALD",
                    "TOTALD",
                    false,
                    this.caixa.vl_aberto || 0
                  ),
              // historico_id: 1,
              obs: "Recebido do caixa ",
              carteira_id: this.caixaProp.carteira_id || null,
            };
            // console.log("mov", this.movObj);
            this.$bvModal.show("modal-bancario");
            this.$emit("carregar");
          }
        });
    },
    async abrirDetalhes() {
      this.pedidosLoading = true;
      // console.log(123, this.caixa);
      try {
        let filter = {
          data: moment(this.caixaProp.data).format("YYYY-MM-DD"),
          cod_moto: this.isMoto ? this.moto.cod_moto : null,
          cod_caixa: !this.isMoto ? this.caixa.cod_caixa : null,
          tipo_entrega:
            this.isMoto && this.moto.cod_moto == 0 ? "ENTREGA" : null,
          cod_empresa: this.caixaProp.cod_empresa,
          comMoto: this.isMoto,
          salao: !this.isMoto ? this.caixa.salao : 0,
        };
        this.pedidos = (await PedidoLib.get(filter)).data;
        // console.log("pedidos,", filter, this.pedidos);
        this.$bvModal.show("modal-pedidos-entregas");
      } catch (e) {
        console.log("erro", e);
      }
      this.pedidosLoading = false;
    },
    abrirImagem(imagem) {
      this.imagem =
        axios.defaults.baseURL +
        "download/" +
        imagem +
        "?token=" +
        this.user.token;
      if (imagem && imagem != "") {
        this.$bvModal.show("modal-imagem");
      }
    },
    async carregar() {
      if (!this.caixaProp) {
        return;
      }

      if (!this.caixaProp.anexos) {
        this.caixaProp.anexos = [];
      }
      if (!this.caixaProp || !this.caixaProp.data) {
        this.caixaProp.data = moment().format("YYYY-MM-DD");
      }
      if (!moment(this.caixaProp.data).isValid()) {
        this.caixaProp.data = moment().format("YYYY-MM-DD");
      }

      this.data = moment(this.caixaProp.data).format("YYYY-MM-DD");
      this.dataSel = moment(this.caixaProp.data).format("YYYY-MM-DD");

      this.caixa = null;
      this.moto = null;
      this.loading = true;
      // let busca = null;
      // console.log(this.$props.caixaProp.cod_caixa);
      // if (!this.isMoto) {
      //   busca = await CaixaLib.getControle(
      //     this.caixaProp.cod_empresa,
      //     moment(this.dataSel).format("YYYY-MM-DD"),
      //     this.caixaProp.cod_caixa
      //   );
      //   if (busca && busca.length > 0) {
      //     this.caixa = busca[0];
      //   }
      // } else {
      //   busca = await CaixaLib.getMotosControle(
      //     this.caixaProp.cod_empresa,
      //     moment(this.dataSel).format("YYYY-MM-DD"),
      //     this.caixaProp.cod_moto
      //   );
      //   if (busca && busca.length > 0) {
      //     this.moto = busca[0];
      //   }
      // }

      if (this.caixaProp.anexos && typeof this.caixaProp.anexos == "string") {
        this.caixaProp.anexos = JSON.parse(this.caixaProp.anexos);
      }
      if (this.isMoto) {
        this.moto = this.caixaProp;
      } else {
        this.caixa = this.caixaProp;
      }
      // console.log("caixa", this.caixaProp);

      //if (this.caixaProp.cod_caixa && this.caixaProp.cod_moto) {
      // console.log(
      //   "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",
      //   this.caixaProp,
      //   this.empresaSelecionada.cod_empresa,
      //   moment(this.caixaProp.data).format("YYYY-MM-DD"),
      //   this.$props.caixaProp.cod_caixa,
      //   this.$props.caixaProp.cod_moto,
      //   this.isMoto ? "moto" : "caixa"
      // );
      let cdEmpresa = this.empresaSelecionada
        ? this.empresaSelecionada.cod_empresa
        : this.caixaProp && this.caixaProp.cod_empresa
        ? this.caixaProp.cod_empresa
        : 1 || 1;

      console.log("emp", this.empresaSelecionada);
      const utcDate = moment.utc(this.caixaProp.data, "YYYY-MM-DD");
      const saoPauloDate = utcDate.tz("America/Sao_Paulo");
      let hasEmp = [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ].find((x) => x.cod_empresa == cdEmpresa);
      if (!hasEmp) {
        cdEmpresa = 1;
      }
      console.log(
        "DATA",
        this.caixaProp,
        saoPauloDate.format("YYYY-MM-DD"),
      );

      if(this.caixaProp.data && typeof this.caixaProp.data == "string" && this.caixaProp.data.length > 10){
        this.caixaProp.data = this.caixaProp.data.substring(0,10)
      }
      
      this.informados = await CaixaLib.getFechamentos(
        cdEmpresa,
        moment(this.caixaProp.data).format("YYYY-MM-DD"),
        this.$props.caixaProp.cod_caixa,
        this.$props.caixaProp.cod_moto,
        this.isMoto ? "moto" : "caixa"
      );
      /*} else {
        this.informados = this.caixaProp.informados;
      }*/
      this.vl_fundo = 0;
      console.log("informados", this.informados);
      if (this.informados && this.informados.length > 0) {
        this.vl_fundo = this.informados
          .filter(
            (x) =>
              (this.isMoto && x.cod_moto && x.cod_moto > 0) ||
              (x.cod_caixa && x.cod_caixa > 0)
          )
          .reduce((ret, vl) => {
            ret += vl.vl_aberto;
            return ret;
          }, 0);
        console.log("informados", this.vl_fundo, this.isMoto);
      }

      this.loading = false;
    },
    async changeValor(item, moto = false) {
      this.loadingVl = true;
      item.loading = true;
      this.$forceUpdate();
      let res = await CaixaLib.changeValorAbertura(
        moto ? item.cod_entrada : item.cod_controle,
        moto ? item.valor_entrada : item.vl_aberto ? item.vl_aberto : 0,
        moto
      );
      if (res.success) {
        this.$swal.fire({
          title: "Valor alterado com sucesso!",
          icon: "success",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });
      }
      item.loading = false;
      this.$forceUpdate();
      this.loadingVl = false;
    },
    val(vl) {
      if (!vl || vl == null || typeof vl == "undefined") {
        return 0;
      } else {
        return vl;
      }
    },
    vl(it, tp, v, soma = false, vlAdd = 0) {
      let vl_ret = 0;

      try {
        if (it && it != null && typeof it != "undefined" && it.length > 0) {
          // console.log("->", it);

          if (v == "TOTAL") {
            // console.log(9999900001)
            vl_ret = it
              .filter(
                (x) =>
                  (!x.excluido || x.excluido === 0) &&
                  //(x.cod_caixa != 4 && x.tipo_entrega != "ENTREGA") ||
                  //x.cod_caixa == 4
                  (x.tipo_entrega != "ENTREGA" || v.indexOf("erro") >= 0) &&
                  ((!soma && x.tipo_entrega == tp) || soma)
                //&& x.tipo_entrega != "saidas"
              )
              .reduce((ret, vt) => {
                ret +=
                  this.val(vt.vl_dim || 0) +
                  this.val(vt.vl_cartao || 0) +
                  this.val(vt.online || 0) +
                  this.val(vt.vale || 0) +
                  this.val(vt.VR || 0) +
                  // this.val(vt.vl_nao_rec || 0) +
                  this.val(vt.PIX || 0);
                //                  this.val(vt.entregar || 0) -
                //this.val(vt.saidas ? vt.saidas || 0 : 0);
                return ret;
              }, 0);
          } else if (v == "TOTALD") {
            // console.log(999999999900002)

            vl_ret = it
              .filter((x) => !x.excluido || x.excluido === 0)
              .reduce((ret, vt) => {
                if (
                  //(x.cod_caixa != 4 && x.tipo_entrega != "ENTREGA") ||
                  //x.cod_caixa == 4
                  vt.tipo_entrega != "ENTREGA" ||
                  v.indexOf("erro") >= 0
                ) {
                  ret += this.val(vt.vl_dim);
                }
                return ret;
              }, 0);

            vl_ret += this.vl_fundo || 0;
            // console.log(444, it)
            // vl_ret -= it
            //   .filter((x) => x.tipo_entrega == "saidas")
            //   .reduce((ret, vt) => {
            //     ret += this.val(vt.saidas);
            //     return ret;
            //   }, 0);
          } else if (it.find((x) => x.tipo_entrega == tp)) {
            // console.log(9900003)

            vl_ret = it.find(
              (x) => (!x.excluido || x.excluido === 0) && x.tipo_entrega == tp
            )[v];
          } else {
            // console.log(9990004)

            try {
              vl_ret = it
                .filter((x) => !x.excluido || x.excluido === 0)
                .reduce((ret, vl) => {
                  if (vl.tipo_entrega != "ENTREGA" || v.indexOf("erro") >= 0) {
                    ret += this.val(vl[v]);
                  }
                  return ret;
                }, 0);
            } catch (e) {
              // console.log("e1", e);
              vl_ret = 0;
            }
          }
        }

        if (vlAdd != 0) {
          vlAdd =
            typeof vlAdd == "string"
              ? parseFloat(
                  vlAdd.replace("R$", "").replace(".", "").replace(",", ".")
                )
              : vlAdd;
          // console.log("vladd", vlAdd);
          vl_ret += vlAdd;
        }
      } catch (e) {
        // console.log("e2", e);
        vl_ret = 0;
      }
      // console.log('aaa',tp, vl_ret)

      return vl_ret;
    },
    getFechamentosCaixa(cod_caixa, cod_moto) {
      // console.log("infos", cod_caixa, cod_moto, this.informados);
      return this.informados
        .filter(
          (x) =>
            (cod_caixa && x.cod_caixa && x.cod_caixa == cod_caixa) ||
            (cod_moto && x.cod_moto && x.cod_moto == cod_moto) ||
            1 == 1
        )
        .reduce(
          (ret, vl) => {
            ret.vl_dinheiro +=
              vl.vl_dinheiro + (vl.vl_moedas || 0) + (vl.vl_moeda || 0);
            ret.vl_cartao += vl.vl_cartao;
            ret.vl_sysprocard += vl.vl_sysprocard;
            ret.vl_pix += vl.vl_pix;
            ret.vl_hidromar += vl.vl_hidromar;
            if (vl.imagem) ret.imagem = vl.imagem;
            return ret;
          },
          {
            vl_dinheiro: 0,
            vl_cartao: 0,
            imagem: "",
            vl_sysprocard: 0,
            vl_pix: 0,
            vl_hidromar: 0,
          }
        );
    },
    delMoto() {
      this.$swal
        .fire({
          title: "Excluir moto?",
          text: "Tem certeza que deseja excluir a moto?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelButtonText: "Não",
        })
        .then(async (c) => {
          if (c.isConfirmed) {
            let res = await CaixaLib.delMotoControle(
              this.caixaProp.cod_entrada,
              this.caixaProp.cod_moto,
              this.caixaProp.data
            );
            if (res.success) {
              this.$swal.fire({
                title: "Moto excluída com sucesso!",
                icon: "success",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 1500,
              });
              this.$emit("carregar");
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.fontMin {
  table,
  table tbody {
    font-size: 10px !important;
  }
}
</style>
